@use "./variables" as *;

.graph {
    .bars {
        align-items: flex-end;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .prices {
        text-align: center;
        justify-content: center;
        flex-wrap: nowrap;
        margin-bottom: 9.6rem;
        font-size: 2.5rem;
        color: $neutral-3;

        div {
            min-width: 80px;
        }

        .price.hover {
            color: black;
        }

        @media (max-width: $bs-md) {
            margin-bottom: 16rem;

            .price {
                font-size: 36px;
                margin-left: -19px;
            }
        }
    }
}

@use "./variables" as *;

.careers {
  .banner {
    background-image: url(../../images/founder_story_5.webp);
  }

  .content {

    img {
      width: 100%;
    }

    .text {
      h3 {
        margin-left: 0;
      }

      .link {
        margin: 6rem 0 6.1rem 0;
      }

      a {
        text-decoration: none;
        color: $primary;
      }

      .section {
        margin: 3rem 0 0.6rem 0;

        .heading {
          display: flex;
          margin-bottom: 5px;
        }

        p {
          max-width: 487px;
          margin-left: 1.95rem;
        }
      }
    }
  }

  .footer {
    text-align: center;
    margin: auto;
    margin-bottom: 4rem;
    background-color: $neutral-4;
    padding: 2rem;

    h5 {
      margin-bottom: 2rem;
    }
  }
}

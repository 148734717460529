@use "./variables" as *;

footer {
  background-color: $secondary;

  .tandev-container img {
    width: 154px;
    height: 64px;
  }

  .everify-container {
    p {
      font-size: 15px;
    }

    .everify-image {
      width: 115px;
      height: 36px;
    }
  }

  .copyright {
    font-size: 16px;
  }

  .footer-col {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .socials {
    margin-top: 15px;

    a:first-child {
      margin: 0 10px 0 -2px;
    }
  }

  p {
    color: #ffffff;
    font-size: 18px;
  }

  h4 {
    color: #ffffff;
    font-size: 18px;
  }

  a {
    text-decoration: none;
    color: #ffffff;
    width: fit-content !important;
  }

  span {
    color: #ffffff;
  }
}

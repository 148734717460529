@use "./variables" as *;

.bar {
    max-width: 220px;
    min-width: 80px;
    padding: 1px;

    .tier-1 {
        h1 {
            padding-top: 10vh;
        }
    }
    .tier-2 {
        h1 {
            padding-top: 15vh;
        }
    }
    .tier-3 {
        h1 {
            padding-top: 20vh;
        }
    }
    .tier-4 {
        h1 {
            padding-top: 25vh;
        }
    }

    h4 {
        text-align: center;
        margin-bottom: 1.5rem;
    }

    h1 {
        text-align: center;
        padding-bottom: 2.1rem;
        margin-bottom: 1.5rem;
        background-color: $primary-2;
        color: $neutral-5;
        border-radius: $bdr-rad-2;
        cursor: pointer;
    }

    .hover {
        background-color: $primary;
    }

    @media (max-width: 514px) {
        .tier-2 {
            h1 {
                padding-top: 13vh;
            }
        }
    }

    @media (min-width: $bs-md) {
        padding: 5px;
    }

    @media (min-width: $bs-lg) {
        padding: 8px;

    }
}

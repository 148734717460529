@use "./variables" as *;

.about {
  margin-bottom: 6.75rem;

  hr {
    border: 1px solid $secondary;
  }

  .banner {
    background-image: url(../../images/culture_11.webp);
  }

  .about-video {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8rem;
    flex-direction: column;

    h2 {
      margin-bottom: 5rem;
      text-align: center;
      z-index: 2;
    }

    .video {
      margin-top: 80px;
      margin-bottom: 100px;
      max-width: 90%;
      margin: 50px;
    }

    @media (max-width: $bs-md) {
      margin-bottom: 0;
    }
  }

  .about-mission {
    text-align: center;
    background-color: $primary;
    margin-bottom: 8rem;
    padding: 3rem 0 3rem;
    color: $neutral-5;

    h2 {
      margin-bottom: 1.5rem;
    }

    p {
      max-width: 900px;
      margin: auto;
      line-height: 34px;

      @media (max-width: $bs-md) {
        padding: 0 3rem;
      }

      @media (max-width: $bs-s) {
        font-size: 18px;
        padding: 0 1rem;
        line-height: 24px;
      }
    }
  }

  .about-team {
    position: relative;

    h2 {
      text-align: center;
      margin-bottom: 8rem;
    }

    .team-container {
      margin: auto;

      .row {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        grid-template-rows: 1fr 1fr;
        grid-gap: 10px;
      }

      .headshot {
        a {
          img {
            max-width: 400px;
            height: 240px;
          }
        }
      }
    }

    .watermark {
      position: absolute;
      top: 200px;
      left: 45%;
      z-index: -1;
      transform: translate(-40%, -40%);

      @media (min-width: $bs-md) {
        top: 0;
        bottom: 20px;
        transform: none;
        left: 0;
      }

      @media (max-width: 767px) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .about-join-team {
    justify-content: center;
    gap: 98px;
    margin: 6.75rem auto 8rem;
    padding-top: 4rem;
    border-top: 1px solid $secondary-2;
    width: fit-content;

    .text {
      max-width: 415px;
      margin-top: 3.9rem;

      p {
        margin: 1.4rem 0 2.1rem;
      }
    }
  }
}

@use "./variables" as *;

.banner {
    text-align: center;
    background-position: center;
    background-size: cover;
    height: 60vh;
    margin-bottom: 60px;

    h1 {
        color: #fff;
    }

    @media (max-width: $bs-s) {
        h1 {
          font-size: 50px;
          line-height: 48px;
        }
    }

    .banner-overlay {
        background-color: #000000aa;
        width: 100%;
        height: 100%;
        padding: 0 3% 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .banner-container {
            @media (max-height: 800px) {
                margin-top: 60px;
            }
        }

        .subtitle {
            margin: 2rem auto 2rem;
            max-width: 680px;
            color: #ffffff;
            line-height: 32px;
      
            @media (max-width: $bs-s) {
              font-size: 22px;
              line-height: 24px;
              margin: 1rem;
            }
        }
    }
}
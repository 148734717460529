@use "./variables" as *;

.specialties {
    margin-bottom: 11.8rem;

    .title {
        max-width: 728px;
        text-align: center;
        margin: 0 auto 3.1rem;

        h2 {
            margin-bottom: 1.5rem;
        }
    }
}

.about-tools {
    height: 308px;
    position: relative;
    margin: 0;

    .scroll-x {
        display: flex;
        gap: 7rem;
        flex-wrap: nowrap;
        overflow-x: scroll;
        scroll-behavior: smooth;
        margin: 0;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @media (max-width: $bs-s) {
            gap: 3rem;
        }

        @media (max-width: $bs-md) {
            gap: 5rem;
        }
    }
}

.carousel-points {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 3rem;

    .border {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $secondary-2;
        border-radius: 22px;
        width: 22px;
        height: 22px;

        .active {
            background-color: $primary;
            border-radius: 15px;
            width: 12px;
            height: 12px;
        }
    }

    @media (max-width: $bs-s) {
        display: none;
    }
}

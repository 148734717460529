@use "./variables" as *;

.list {
    max-width: 220px;
    padding: 0;
    justify-items: center;
    font-family: 'Roboto', sans-serif;

    .heading {
        margin-bottom: 15px;
    }

    @media (max-width: $bs-md) {
        .active {
            display: block;
            position: absolute;
            left: 5%;
        }

        .inactive {
            display: none;
        }

        .heading {
            font-size: 14px;
        }

        .text {
            p {
                font-size: 12px;
            }
        }
    }

    @media (min-width: $bs-md) {
        display: block;

        .heading {
            font-size: 17px;
        }

        .text {
            p {
                font-size: 15px;
            }
        }
    }

    @media (min-width: $bs-lg) {
        display: block;

        .heading {
            font-size: 22px;
        }

        .text {
            p {
                font-size: 18px;
            }
        }
    }

    @media (min-width: $bs-xl) {
        display: block;

        .heading {
            font-size: 28px;
        }

        .text {
            p {
                font-size: 24px;
            }
        }
    }

    &-hover {
        display: block;
        padding: 0;
        justify-items: center;
        color: black;

        .text {
            display: flex;
            margin-top: 1.3rem;

            p {
                font-size: 20px;
            }
        }

        @media (min-width: $bs-md) {
            .heading {
                font-size: 15px;
            }

            .text {
                p {
                    font-size: 12px;
                }
            }
        }

        @media (min-width: $bs-lg) {
            .heading {
                font-size: 20px;
            }

            .text {
                p {
                    font-size: 18px;
                }
            }
        }

        @media (min-width: $bs-xl) {
            .text {
                p {
                    font-size: 20px;
                }
            }
        }
    }
}

$primary: #197bc2;
$primary-2: #75b0da;
$primary-3: #bad7ec;
$primary-4: #e7f1f8;

$secondary: #202942;
$secondary-2: #073ba3;

$neutral: #0f131f;
$neutral-2: #4d5468;
$neutral-3: #9498a4;
$neutral-4: #ececef;
$neutral-5: #fafafb;

$bdr-rad: 0.5rem;
$bdr-rad-2: 0.25rem;

$bs-s: 576px;
$bs-md: 768px;
$bs-lg: 992px;
$bs-xl: 1200px;
$bs-xxl: 1400px;

@use "./variables" as *;

.services {
    h2 {
        text-align: center;
        margin-top: 12.5rem;
        margin-bottom: 4rem;
    }

    .banner {
        background-image: url(../../images/worker_on_laptop.webp);
    }
}
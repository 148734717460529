@use "./variables" as *;

.blog {
    .loading {
        display: flex;
        height: 100vh;

        img {
            display: block;
            margin: auto;
            width: 15vw;
            -webkit-animation: breathing 5s ease-out infinite normal;
            animation: breathing 3s ease-out infinite normal;
        }

        @-webkit-keyframes breathing {
            0% {
                -webkit-transform: scale(0.9);
                transform: scale(0.9);
            }

            25% {
                -webkit-transform: scale(1);
                transform: scale(1);
            }

            60% {
                -webkit-transform: scale(0.9);
                transform: scale(0.9);
            }

            100% {
                -webkit-transform: scale(0.9);
                transform: scale(0.9);
            }
        }

        @keyframes breathing {
            0% {
                -webkit-transform: scale(0.9);
                -ms-transform: scale(0.9);
                transform: scale(0.9);
            }

            25% {
                -webkit-transform: scale(1);
                -ms-transform: scale(1);
                transform: scale(1);
            }

            60% {
                -webkit-transform: scale(0.9);
                -ms-transform: scale(0.9);
                transform: scale(0.9);
            }

            100% {
                -webkit-transform: scale(0.9);
                -ms-transform: scale(0.9);
                transform: scale(0.9);
            }
        }
    }
    .blog-container {
        margin: 8rem auto;
        max-width: 900px;

        .blog-back {
            font-size: 18px;
            color: black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .blog-title {
            font-family: "Playfair Display", serif;
            font-weight: 900;
            font-size: 56px;
            line-height: 54px;
            margin-top: 3rem;
            margin-bottom: 3rem;

            @media (max-width: $bs-s) {
                font-size: 40px;
                line-height: 44px;
            }
        }

        .blog-content {
            div {
                font-size: 21px;
                line-height: 24px;

                @media (max-width: $bs-s) {
                    font-size: 19px;
                }

                b {
                    font-weight: 700;
                }

                i {
                    font-style: italic;
                }

                span {
                    font-size: 28px !important;
                    line-height: 30px;

                    @media (max-width: $bs-s) {
                        font-size: 25px !important;
                    }
                }
            }

            ul {
                margin: 10px 0;
                li {
                    padding: 3px;
                    margin-left: 20px;
                    list-style-type: square;
                }
            }

            a {
                text-decoration: none;
            }
        }

        @media (max-width: $bs-xl) {
            margin: 8rem 5rem;
        }

        @media (max-width: $bs-md) {
            margin: 8rem 3rem;
        }
    }
}

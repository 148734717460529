@use "./variables" as *;

.service-blocks {
    text-align: center;
    margin-bottom: 10rem;
    margin-top: 10rem;

    h3 {
        margin-top: 10.5rem;
    }

    .service-blocks-video {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1400px) {
            display: none;
        }
    }

    .blocks {
        margin: auto;
        justify-content: space-around;
        max-width: 1100px;

        .block {
            width: 300px;
            background-color: $neutral-4;
            border: 1px solid $neutral-4;
            box-shadow: 0px 3px 16px 8px rgba(0, 0, 0, 0.05);
            border-radius: $bdr-rad;
            padding: 0;
        }

        h5 {
            font-size: 21px;
        }

        .blocks-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .block {
                height: 230px;
                margin: 10px 0;
            }

            @media (max-width: 1400px) {
                flex-direction: row;

                .block {
                    margin: 1rem 2rem;
                    min-width: 300px;
                }
            }

            @media (max-width: 1080px) {
                flex-direction: row;

                .block {
                    margin: .5rem 1rem;
                    min-width: 250px;
                }
            } 
            
            @media (max-width: 1080px) and (min-width: 992px) {
                h5 {
                     margin: 0px !important;
                     padding: 1.5rem !important;
                }

                .bottom-block {
                        padding: 1rem !important;
                }
            }

            @media (max-width: 830px) {
                flex-direction: column;

                .block {
                    margin: .5rem 0;

                    h5 {
                        padding-top: 1.5rem;
                    }
                }
            } 
        }

        @media (max-width: $bs-s) {
            justify-content: center;
            gap: 10px;

            h5 {
                font-size: 18px !important;
                padding-bottom: 1rem;
            }
        }
    }

    @media (max-width: $bs-s) {
        margin-bottom: 6rem;
    }
}

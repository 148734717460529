@use "./variables" as *;

.blog-landing {
    .banner {
        background-image: url(../../images/founder_story_3.webp);
    }

    .blog-container {
        margin-top: 8rem;
        margin-bottom: 8rem;

        .blog-blogs {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            max-width: 1296px;

            &::after {
                flex: auto;
            }

            @media (min-width: 1200px) and (max-width: 1398px) {
                margin: 0 90px;
            }
        }

        a {
            text-decoration: none;
            color: black;
            margin-top: 3rem;

            .blog-item {
                overflow: hidden;
                position: relative;
                width: 400px;
                height: 100%;
                border: 1px solid $neutral-4;
                border-radius: $bdr-rad;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @media (max-width: $bs-s) {
                    width: 350px;
                }

                .blog-info {
                    img {
                        width: 100%;
                        object-fit: cover;
                        margin-bottom: 1rem;
                    }
                    .category {
                        margin-left: 1rem;
                        color: $neutral-2;
                    }

                    .title {
                        margin: 0.5rem 0.3rem 0.5rem 1rem;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 27px;
                    }
                }

                .author-info {
                    display: flex;
                    align-items: center;
                    margin: 0.5rem;

                    img {
                        height: 75px;
                        width: 75px;
                        border-radius: 75%;

                        @media (max-width: $bs-s) {
                            height: 45px;
                            width: 55px;
                        }
                    }

                    .author {
                        margin-left: 0.5rem;
                    }

                    .time {
                        margin-left: auto;
                        color: $neutral-3;
                    }
                }
            }

            .blog-item:hover {
                cursor: pointer;
            }
        }

        .blog-button {
            text-align: center;

            .blog-more {
                margin-top: 5rem;
                text-align: center;
                margin-left: auto; // Add this
                margin-right: auto; // Add this
                width: 150px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

@use "./variables" as *;

.find-us {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $neutral-4;
    flex-wrap: wrap;

    img {
        margin-right: 2.5rem;
    }

    .clutch {
        width: 120px;
    }

    .goodfirms {
        width: 200px;
    }
}
@use "./variables" as *;

.our-team {
  justify-content: center;
  gap: 98px;
  margin-bottom: 9rem;

  @media (max-width: $bs-md) {
    margin: 5rem 0;
  }

  img {
    width: 100%;
    height: 100%;
  }

  h3 {
    margin-left: 0;
  }

  .text {
    margin-top: 2rem;

    p {
      margin: 1.4rem 0 2.1rem;
    }
  }
}

@use "./variables" as *;

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}

.td-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  max-width: 700px;
  height: auto;
  z-index: 2000;
  background: #ffffff;
  box-shadow: 0px 0px 16px 5px rgba(148, 152, 164, 0.15);
  border-radius: 8px;
  animation: fadein 0.2s;

  img {
    float: right;
  }

  .modal_close {
    cursor: pointer;
    margin-right: 0.75rem;
  }

  @media (max-width: $bs-md) {
    height: 100vh;
    overflow: scroll;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

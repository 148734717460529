@use "./variables" as *;

.faq {
  margin-top: 12.4rem;

  .question-container {
    overflow: hidden;

    .question-icon {
      margin: 5px 0;
      border-bottom: 1px solid rgba(148, 152, 164, 0.5);
      padding-bottom: 0.5rem;

      .title {
        &-open {
          font-weight: 600;
        }
      }

      .carrot {
        &-open {
          rotate: 90deg;
          transition: 0.2s ease-in;
          margin-right: 15px;
          margin-bottom: -5px;
        }

        &-close {
          margin-top: 6px;
          rotate: 0deg;
          transition: 0.2s ease-in;
        }
      }
    }

    &:hover {
      p {
        font-weight: 600;
      }
    }

    p {
      font-size: 18px;
    }

    .open {
      transform: translateY(0%);
      transition: all 0.4s ease;
      margin-top: 10px;
    }

    .closed {
      transform: translateY(100%);
      transition: all 0.4s ease;
      margin-top: -3rem;
    }

    @media (max-width: 308px) {
      width: 90%;
      margin: auto;
    }

    @media (min-width: 486px) {
      &:nth-child(2) {
        .closed {
          margin-top: -65px;
        }
      }
    }

    @media (min-width: 523px) {
      &:nth-child(2) {
        .closed {
          margin-top: -45px;
        }
      }
    }

    @media (min-width: $bs-s) {
      &:nth-child(2) {
        .closed {
          margin-top: -45px;
        }
      }
    }

    @media (min-width: $bs-md) {
      &:nth-child(1) {
        .closed {
          margin-top: -30px;
        }
      }
    }

    @media (min-width: $bs-lg) {
      &:nth-child(1) {
        .closed {
          margin-top: -30px;
        }
      }

      &:nth-child(3) {
        .closed {
          margin-top: 0;
        }
      }
    }

    @media (min-width: $bs-xl) {
      &:nth-child(1) {
        .closed {
          margin-top: -40px;
        }
      }

      &:nth-child(2) {
        .closed {
          margin-top: -40px;
        }
      }
    }

    @media (min-width: 1400px) {
      &:nth-child(1) {
        .closed {
          margin-top: -20px;
        }
      }
    }
  }
}

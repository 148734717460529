@use "./variables" as *;

.video {
    border-radius: $bdr-rad;
    border: solid $primary;
    border-width: 5px;

    iframe {
        display: block;
        border-radius: 0.2rem;
        background-color: $primary;
        max-width: 100%;

        @media (max-width: $bs-lg) {
            background-color: white;
            border-radius: 0.5rem;
            background-color: none;
        }
    }

    @media (max-width: $bs-lg) {
        border: none;
    }
}

.chip {
    font-size: 12px;
    font-weight: 400;
    width: fit-content;

    &-1 {
        border: solid 1px rgba(0, 0, 0, 0.07);
        padding: 3px 9px;
        border-radius: 60px;

        &::after {
            content: "";
            display: inline-block;
            width: 6px;
            height: 6px;
            -moz-border-radius: 7px;
            -webkit-border-radius: 7px;
            border-radius: 7px;
            background-color: rgba(7, 59, 163, 1);
            margin-left: 10px;
            vertical-align: middle;
        }

        &::before {
            content: "";
            display: inline-block;
            width: 6px;
            height: 6px;
            -moz-border-radius: 7px;
            -webkit-border-radius: 7px;
            border-radius: 7px;
            background-color: rgba(7, 59, 163, 1);
            margin-right: 10px;
            vertical-align: middle;
        }
    }

    &-2 {
    }
}

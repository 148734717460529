@use "./variables" as *;

.homepage {
  &-indent {
    margin: 30px !important;

    @media (max-width: $bs-md) {
      margin: 0 !important;
    }
  }

  &-title {
    height: 110vh;

    img.background {
      position: absolute;
      left: 0;
      z-index: -1;
      object-fit: cover;
      width: 100vw;
      height: inherit;
    }

    &-overlay {
      background-color: #00000096;
      width: 100vw;
      height: 110vh;
      position: absolute;
      right: 0;
      z-index: 1;

      @media (max-width: $bs-md) {
        height: 100vh;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: inherit;

      .homepage-title-content {
        position: relative;
        z-index: 2;
        padding-top: 150px;

        .chip {
          border: solid 1px rgba(255, 255, 255, 0.16);
          border-radius: 20px;
          padding: 3px 15px;
          width: fit-content;
          display: flex;

          p {
            color: $neutral-5;
            font-size: 13px;
            margin-left: 10px;

            span {
              font-weight: 700;
            }
          }
        }

        @media (max-width: 1250px) {
          padding-top: 75px;
        }

        @media (max-width: 991px) {
          padding-top: 150px;
        }

        @media (max-width: 576px) {
          padding-top: 100px;
        }
      }

      .homepage-title-grid {
        position: relative;
        z-index: 2;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        color: white;
        border-radius: 12px 12px 0 0;
        background-color: rgba(255, 255, 255, 0.08);

        .block {
          display: flex;
          padding: 25px 40px;
          align-items: center;

          &:nth-child(-n + 3) {
            border-bottom: dashed 1px rgba(255, 255, 255, 0.08);
          }

          &:nth-child(-n + 2) {
            border-right: dashed 1px rgba(255, 255, 255, 0.08);
          }

          &:nth-child(3n + 2) {
            border-right: dashed 1px rgba(255, 255, 255, 0.08);
            border-left: dashed 1px rgba(255, 255, 255, 0.08);
          }

          &-text {
            color: #ffffff;

            .heading {
              opacity: 0.5;
              font-weight: 500;
              font-size: 14px;
            }

            .text {
              font-weight: 700;
              font-size: 17px;
              max-width: 200px;
            }
          }
        }

        @media (max-width: 991px) {
          display: none;
        }
      }
    }

    h2 {
      color: $neutral-5;

      .gradient-text {
        background: linear-gradient(to right, #0d5e97, #073ba3 40%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &-bottom {
          background: linear-gradient(to right, #197bc2 10%, #073ba3);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    @media (max-width: $bs-md) {
      height: 100vh;
    }

    @media (max-width: 768px) {
      h2 {
        font-size: 38px;
      }
    }

    @media (max-width: 523px) {
      h2 {
        font-size: 30px;
      }
    }

    @media (max-width: 425px) {
      h2 {
        font-size: 25px;
      }
    }
  }

  &-tech-stack {
    .heading {
      margin-left: 10vw;

      h1 {
        margin-bottom: 0;
      }

      .text {
        margin-top: 15px;
        font-size: 16px;
        font-weight: 400;
      }
    }

    .tech-stack {
      &-container {
        display: flex;
        gap: 30px;
        justify-content: center;

        @media (max-width: $bs-md) {
          flex-wrap: wrap;
        }
      }

      &-card {
        padding: 40px 10px;
        height: 280px;
        width: 200px;
        color: $neutral-5;
        border-radius: 11px;
        transition: 0.4s;

        .card {
          &-heading {
            font-weight: 500;
          }
          &-text,
          &-cta {
            display: none;
          }
        }

        &:hover {
          transition: 0.4s;
          width: 540px;
          padding: 40px 30px;
          display: flex;
          flex-direction: column;

          .card {
            &-text,
            &-cta {
              display: block;
            }

            &-cta {
              margin-top: auto;
              font-weight: 600;

              .arrow {
                transform: rotate(180deg);
                color: $neutral-5 !important;
                margin: 0 0 2px 6px !important;
              }
            }
          }

          &:first-child {
            background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../images/tech-stack1.webp");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }

          &:nth-child(2) {
            background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../images/tech-stack2.webp");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }

          &:nth-child(3) {
            background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../images/tech-stack3.webp");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }

          &:last-child {
            background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../images/tech-stack4.webp");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }

        &:first-child {
          background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../images/tech-stack1.webp");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }

        &:nth-child(2) {
          background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../images/tech-stack2.webp");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }

        &:nth-child(3) {
          background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../images/tech-stack3.webp");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }

        &:last-child {
          background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../images/tech-stack4.webp");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
  }

  &-our-model {
    .above-heading {
      font-weight: 500;
      color: #505050;
      font-size: 18px;
    }

    .video {
      overflow: hidden;
      border: none;
      border-radius: 16px;
      margin: auto;
    }

    .our-model-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .heading .cta {
        button,
        .arrow {
          color: $neutral !important;
          font-weight: 400;
        }
      }

      .text {
        font-weight: 400;
        line-height: 24px;
        max-width: 800px;

        .graph {
          display: flex;
          gap: 50px;
          font-size: 14px;
          font-weight: 600;

          .progress {
            width: 230px;
            height: 7px;
            background-color: #f2f2f2;
          }

          .progress::-webkit-progress-value {
            background-image: linear-gradient(to right, #197bc2, #073ba3);
          }

          @media (max-width: $bs-lg) {
            flex-wrap: wrap;
            gap: 25px;
          }
        }
      }
    }
  }

  &-technologies {
    .technologies {
      &-overflow {
        position: relative;
        overflow: scroll;
        height: 200px;
        margin-left: -5vw;
      }

      &-container {
        display: flex;
        gap: 50px;
        position: absolute;
        left: 0;
        right: 0;
        padding: 25px;

        .spacer {
          width: 25px;
        }
      }

      &-card {
        width: 300px;
        min-width: 300px;
        height: 145px;
        border: solid 1px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        padding: 0px 15px;
        overflow: hidden;

        .image {
          padding: 0;

          @media (max-width: $bs-md) {
            width: fit-content;
          }
        }

        .years {
          padding-bottom: 20px;
          width: 74px;
          height: 85px;
          position: relative;
          top: 0;
          margin-top: -100px;
          transition: top ease-in 0.3s;
          background-color: #073ba3;
          color: #fafafb;
          display: flex;
          align-items: end;
          justify-content: center;
          font-size: 28px;
          font-weight: 600;
          font-family: "Playfair Display", serif;

          @media (max-width: $bs-md) {
            margin-top: -20px;
          }
        }

        .heading {
          font-size: 16px;
          font-weight: 700;
          margin: 10px 0 5px 0;
        }

        .text {
          font-size: 14px;
          font-weight: 400;
        }

        &:hover {
          .years {
            top: 80px;

            @media (max-width: $bs-md) {
              top: 0;
            }
          }
        }

        @media (max-width: $bs-md) {
          .row {
            justify-content: space-between;
          }
        }
      }
    }
  }

  &-plans {
    background-color: #fafafa;
    display: flex;

    .heading {
      font-size: 24px;
      font-weight: 500;
    }

    .team-building-plan {
      &-cards {
        display: flex;
        gap: 30px;

        @media (max-width: $bs-md) {
          flex-wrap: wrap;
        }
      }

      &-card {
        background-color: #fff;

        .heading {
          font-size: 16px;
          font-weight: 500;
        }

        .text {
          font-size: 15px;
        }

        .cta .td-button-6 {
          color: rgba(7, 59, 163, 1) !important;

          .arrow {
            color: rgba(7, 59, 163, 1) !important;
          }
        }
      }

      @media (max-width: $bs-md) {
        padding: 20px;
      }
    }

    .fixed-plan {
      background-image: linear-gradient(rgba(4, 38, 56, 0.9), rgba(4, 38, 56, 0.9)), url(../../images/fixed_plan.png);
      color: $neutral-5;
      max-width: 100%;

      .heading {
        font-size: 24px;
        font-weight: 500;
        width: 250px;
      }

      .text {
        font-size: 16px;
        font-weight: 400;
      }

      .cta .td-button-6 {
        font-weight: 400;
      }
    }

    @media (max-width: $bs-lg) {
      flex-wrap: wrap;
    }
  }

  &-our-team {
    .heading {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      align-items: center;
      flex-wrap: wrap;

      .cta .td-button-6 {
        color: $neutral;

        .arrow {
          color: $neutral !important;
        }
      }

      span {
        font-style: italic;
        color: #073ba3;
      }
    }

    .content {
      display: flex;
      overflow: scroll;
      gap: 35px;
      overflow-y: hidden;

      &:hover {

      }
    }
  }

  .container.team {
    @media (max-width: $bs-md) {
      max-width: fit-content;
    }
  }

  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2 {
      margin-top: 80px;
      z-index: 2;
      position: relative;
      text-align: center;
    }

    .video {
      margin-top: 80px;
      margin-bottom: 100px;
      max-width: 90%;
      margin: 50px;
    }
  }

  .homepage-benefits {
    background-color: $primary;
    text-align: center;
    color: $neutral-5;
    padding-bottom: 5.5rem;

    .heading {
      padding-top: 5.5rem;
      margin-bottom: 2rem;
      margin-top: 0;
    }

    .benefits {
      margin-top: 4rem;
      color: $neutral;
      gap: 101px;
      justify-content: center;

      .col {
        padding: 2.8rem 2rem;
        border-radius: $bdr-rad-2;
      }

      .benefit-1 {
        background-color: $neutral-5;
        max-width: 332px;

        h4 {
          margin: 6px 0 2rem;
        }
      }

      .benefit-2 {
        background-color: $neutral-5;
        max-width: 332px;

        h4 {
          margin: 2.8rem 0 2rem;
        }
      }

      .benefit-3 {
        background-color: $neutral-5;
        max-width: 332px;

        h4 {
          margin: 2rem 0 1.01rem;
        }
      }
    }
  }

  .homepage-story {
    text-align: center;
    background-color: $primary;
    margin-bottom: 8rem;
    padding: 3rem 0 3rem;
    color: $neutral-5;

    h2 {
      margin-bottom: 1.5rem;
    }

    p {
      max-width: 900px;
      margin: auto;
      line-height: 34px;

      @media (max-width: $bs-md) {
        padding: 0 3rem;
      }

      @media (max-width: $bs-s) {
        font-size: 18px;
        padding: 0 1rem;
        line-height: 24px;
      }
    }
  }

  .homepage-clients {
    text-align: center;
    margin-bottom: 5rem;

    .client-title {
      margin-bottom: 5rem;
    }

    .logos {
      justify-content: center;
      gap: 46px;
    }

    @media (min-width: 486px) {
      margin-top: 5rem;
      margin-bottom: 8rem;
    }

    @media (min-width: $bs-s) {
      margin-top: 9rem;
      margin-bottom: 8rem;
    }

    @media (min-width: $bs-md) {
      margin-top: 11rem;
      margin-bottom: 12rem;
    }

    @media (min-width: $bs-lg) {
      margin-top: 15rem;
      margin-bottom: 15rem;
    }
  }

  .homepage-contact {
    background-color: $neutral-4;
    padding: 4rem 0;

    p {
      font-size: 18px;

      @media (max-width: $bs-md) {
        margin-bottom: 2rem;
      }
    }
  }
}

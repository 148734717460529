@use "./variables" as *;

.contact-form {
  .error {
    border: 1px solid red;
  }

  .error-message {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    color: red;
  }

  input {
    border-radius: 0.5rem;
    border: 1px solid $neutral-3;
    background-color: $neutral-5;
    height: 3.25rem;
    padding: 1rem;
    width: 100%;
    display: block;
    box-sizing: border-box;
  }

  textarea {
    border-radius: 0.5rem;
    border: 1px solid $neutral-3;
    background-color: $neutral-5;
    padding: 1rem;
    width: 100%;
  }

  h3 {
    display: flex;
    justify-content: center;
    font-size: 3.5rem;
  }

  .contact-form--button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.25rem;

    button {
      margin-left: 2rem;
    }
  }
}

@use "./variables" as *;

.diamond {
    max-width: 16px;
    min-width: 16px;
    height: 16px;
    background-color: $primary-3;
    transform: rotate(45deg);
    margin: 5px 15px 0 0;

    &-hover {
        max-width: 16px;
        min-width: 16px;
        height: 16px;
        background-color: $primary;
        transform: rotate(45deg);
        margin: 5px 15px 0 0;
        color: black;
    }
}

@use "./variables" as *;

.td-button {
  position: relative;
  display: inline-block;
  z-index: 10;
  padding: 20px 2rem;
  border-radius: $bdr-rad;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  border: 0;
  text-align: center;
  transition: 0.2s;

  &.success {
    background-color: green;
    &:hover {
      box-shadow: 0 0 0 $neutral-2;
      transform: translate(0, 0);
    }
  }

  &.failed {
    background-color: red;
    &:hover {
      box-shadow: 0 0 0 $neutral-2;
      transform: translate(0, 0);
    }
  }

  &.neutral-3 {
    background-color: #ffffff;
    color: $neutral-3;
    border: 1px solid $neutral-3;

    @media (hover: hover) {
      &:hover {
        color: $neutral-2;
        box-shadow: 0 0 5px #0000005d;
      }
    }

    @media (hover: none) {
      &:active {
        background-color: $neutral-2;
      }
    }

    &:active {
      transform: translate(0, 0);
      box-shadow: none;
    }
  }

  &-1 {
    background: linear-gradient(to right, #197bc2, #073ba3);
    color: $neutral-5;
    padding: 12px 12px 10px 12px;
    margin-left: 0;
    font-size: 14px;
    font-weight: 700;
    transform: scale(1);
    transition: 0.2s all ease;
    border-radius: 6px;

    .arrow {
      transform: rotate(180deg);
      color: $neutral-5 !important;
      margin: 0 0 2px 6px !important;
    }

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1.05);
    }
  }

  &-2 {
    background-color: $secondary-2;
    color: $neutral-5;

    &:hover {
      color: $neutral-5;
    }
  }

  &-3 {
    background-color: white;
    color: $primary;
    border: solid 1px $primary;

    &:hover {
      color: $primary;
    }

    @media (hover: hover) {
      &:hover {
        box-shadow: 4px 4px 0 $neutral-2;
        transform: translate(-4px, -4px);
      }
    }

    @media (hover: none) {
      &:active {
        background-color: $primary;
        color: #ffffff;
      }
    }

    &:active {
      transform: translate(0, 0);
      box-shadow: none;
    }
  }

  &-4 {
    background-color: white;
    color: $primary;
    padding: 0;
    margin-left: 0;

    &:hover {
      color: $primary;
      text-decoration: underline;
      text-decoration-thickness: 2px;
    }

    &:active {
      text-decoration: underline;
      text-decoration-thickness: 2px;
    }
  }

  &-5 {
    background-color: rgba(255, 255, 255, 0.12);
    color: $neutral-5;
    padding: 9px 12px;
    margin-left: 0;
    font-size: 12px;
    font-weight: 700;
    border: solid 1px $neutral-5;
    transform: scale(1);
    transition: 0.2s all ease;

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1.05);
    }
  }

  &-6 {
    color: $neutral-5;
    padding: 0;
    background: none;
    font-size: 15px;

    .arrow {
      transform: rotate(180deg);
      color: $neutral-5 !important;
      margin: 0 0 2px 6px !important;
    }

    &:hover {
      transform: scale(1.03);
      color: $neutral-5;
    }
  }
}

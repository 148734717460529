@use "./variables" as *;

html {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
}

h1 {
  font-size: 54px;
  line-height: 72px;
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-weight: 500;

  @media (max-width: $bs-md) {
    font-size: 48px;
  }
}

h2 {
  font-size: 44px;
  line-height: 48px;
  font-weight: 700;

  @media (max-width: $bs-md) {
    font-size: 40px;
  }
}

h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  background: linear-gradient(to right, #197bc2, #073ba3 30%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 33px;

  @media (max-width: $bs-s) {
    font-size: 28px;
  }
}

h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}

h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}

p {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
}

// Override classes for body 2 and body 3
.body-2 {
  font-weight: 300;
  font-size: 21px;
  line-height: 24px;

  @media (max-width: $bs-s) {
    font-size: 18px;
  }
}

.body-3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

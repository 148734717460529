@use "./variables" as *;

nav {
  transition: 300ms ease-in-out;
  margin-bottom: -85px;
  border-bottom: solid  1px rgba(255, 255, 255, 0.04);
  z-index: 100000;

  .navbar-collapse {
    justify-content: center;
  }

  /* Hamburger icon animation */
  .navbar-toggler {
    width: 20px;
    height: 20px;
    position: relative;
    transition: .5s ease-in-out;
  
    &.navbar-toggler,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border: 0;
    }
  
    span {
      margin: 0;
      padding: 0;
    }
  
    .top-bar {
      margin-top: 0;
      transform: rotate(135deg);
    }
  
    .middle-bar {
      opacity: 0;
      filter: alpha(opacity=0);
    }
  
    .bottom-bar {
      margin-top: 0;
      transform: rotate(-135deg);
    }
  
    &.collapsed {
  
      .top-bar {
        margin-top: -20px;
        transform: rotate(0deg);
      }
  
      .middle-bar {
        opacity: 1;
        filter: alpha(opacity=100);
      }
  
      .bottom-bar {
        margin-top: 20px;
        transform: rotate(0deg);
      }
    }
  }
  
  .toggler-icon {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $neutral-5;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  
  .middle-bar {
    margin-top: 0px;
  }

  &.active {
    background-color: $secondary !important;

    .text {
      transition: 400ms ease-in-out;
      color: $neutral-5 !important;
    }
  }

  a img {
    width: 95px;
    height: 40px;
  }

  ul {
    gap: 30px;

    .text {
      display: block;
      text-decoration: none;
      color: $neutral-5;
      transition: 200ms ease-in-out;
      padding: 4px;
      font-size: 14px;
      font-weight: bold;

      &:hover {
        box-shadow: 0 3px 0 #fafafb80;
      }
    }

    .current {
      box-shadow: 0 3px 0 #fafafb80;
    }

    .socials {
      margin-left: -50px;
    }

    .spacer {
      height: 52vh;
    }

    @media (max-width: $bs-s) {
      gap: 45px;
    }
  }
}

@use "./variables" as *;

.tool {
    min-width: 200px;

    h4 {
        margin: 1.25rem 0 1.1rem;
    }

    h6 {
        color: $primary;
    }
}

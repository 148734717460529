@use "./variables" as *;

.headshot {
  .headshot-img {
    color: black;

    svg {
      color: #0077B5;
      margin-bottom: 4px;
      margin-left: 3px;
    }

    img {
      object-fit: cover;
      width: 280px;
      height: 315px;
      border-radius: 15px;
    }
  }

  a {
    display: block;
    margin-top: 0.25rem;
    text-decoration: none;
    color: $primary;

    .name {
      justify-content: space-between;
      align-items: center;
      height: 20px;
    }
  }
}

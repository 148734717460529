@use "./variables" as *;

.carousel {
    position: relative;
    overflow: hidden;
    height: 575px;
    // display: none;

    .chip {
        margin: auto;
    }

    &-container {
        display: flex;
        overflow: hidden;

        .carousel-card {
            position: relative;
            width: 245px;
            height: 365px;
            overflow: hidden;
            border-radius: 16px;
            margin: auto;

            img {
                display: block;
                width: 100%;
                height: 330px;
                object-fit: fill;
                border-radius: 10px;
                margin-top: 15px;
                cursor: pointer;

                @media (max-width: $bs-s) {
                    height: 250px;
                    object-fit: cover;
                }
            }

            .overlay {
                opacity: 0;
                transition: all 0.4s;
                position: absolute;
                top: 15px;
                border-radius: 10px;
                background: rgba(0, 0, 0, 0.7);
                width: 100%;
                height: 330px;
                color: white;

                .title {
                    padding: 10px;
                    font-size: 24px;

                    @media (max-width: $bs-lg) {
                        padding: 8px;
                        font-size: 20px;
                    }

                    @media (max-width: $bs-s) {
                        padding: 4px 6px;
                        font-size: 15px;
                    }
                }

                .body {
                    padding: 10px;
                    font-size: 15px;

                    @media (max-width: $bs-lg) {
                        padding: 8px;
                        font-size: 13px;
                    }

                    @media (max-width: $bs-s) {
                        padding: 4px 7px;
                        font-size: 11px;
                    }
                }

                @media (max-width: $bs-s) {
                    height: 250px;
                }
            }

            &:hover {
                .overlay {
                    opacity: 1;
                    position: absolute;
                    top: 15px;
                    border-radius: 10px;
                    background: rgba(0, 0, 0, 0.7);
                    width: 100%;
                    height: 330px;
                    color: white;

                    .title {
                        padding: 10px;
                        font-size: 24px;

                        @media (max-width: $bs-lg) {
                            padding: 8px;
                            font-size: 20px;
                        }

                        @media (max-width: $bs-s) {
                            padding: 4px 7px;
                            font-size: 15px;
                        }
                    }

                    .body {
                        padding: 10px;
                        font-size: 15px;

                        @media (max-width: $bs-lg) {
                            padding: 8px;
                            font-size: 13px;
                        }

                        @media (max-width: $bs-s) {
                            padding: 4px 7px;
                            font-size: 11px;
                        }
                    }

                    @media (max-width: $bs-s) {
                        height: 250px;
                    }
                }
            }

            @media (max-width: $bs-s) {
                .overlay {
                    opacity: 1;
                    position: absolute;
                    top: 15px;
                    border-radius: 10px;
                    background: rgba(0, 0, 0, 0.7);
                    width: 100%;
                    height: 330px;
                    color: white;

                    .title {
                        padding: 10px;
                        font-size: 24px;

                        @media (max-width: $bs-lg) {
                            padding: 8px;
                            font-size: 20px;
                        }

                        @media (max-width: $bs-s) {
                            padding: 4px 7px;
                            font-size: 15px;
                        }
                    }

                    .body {
                        padding: 10px;
                        font-size: 15px;

                        @media (max-width: $bs-lg) {
                            padding: 8px;
                            font-size: 13px;
                        }

                        @media (max-width: $bs-s) {
                            padding: 4px 7px;
                            font-size: 11px;
                        }
                    }

                    @media (max-width: $bs-s) {
                        height: 250px;
                    }
                }
            }

            &:nth-child(-n + 2) {
                transform: perspective(100px) rotateY(4deg);
                transform-origin: center;

                @media (max-width: $bs-md) {
                    .overlay {
                        display: none;
                    }
                }
            }

            &:nth-child(n + 4) {
                transform: perspective(100px) rotateY(-4deg);
                transform-origin: center;

                @media (max-width: $bs-md) {
                    .overlay {
                        display: none;
                    }
                }
            }

            @media (max-width: $bs-md) {
                &:first-child {
                    display: none;
                }

                &:nth-child(5) {
                    display: none;
                }
            }
        }

        @media (max-width: $bs-md) {
            position: relative;
        }

        .svg {
            opacity: 0.5;
            position: absolute;
            bottom: 30%;
            height: fit-content;
            background-color: black;
            border-radius: 0px 30px 30px 0px;

            @media (max-width: $bs-md) {
                display: none;
            }
        }

        .svg:last-child {
            opacity: 0.5;
            transform: rotate(180deg);
            position: absolute;
            right: 0;
            bottom: 30%;
            height: fit-content;
            background-color: black;
            border-radius: 0px 30px 30px 0px;

            @media (max-width: $bs-md) {
                display: none;
            }
        }
    }

    @media (max-width: $bs-md) {
        margin-left: 0;
        overflow: visible;
        margin-bottom: 10rem;
        width: 100%;
    }
}
